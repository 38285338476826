import * as React from 'react';
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import PostCard from '../components/PostCard'
import { Business, BusinessData } from '../types';
import { useEffect, useState } from 'react';
import businessData from '../app/data/business.json';
import SideBar from "../components/SideBar";

const Item = styled(Sheet)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
    ...theme.typography['body-sm'],
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 4,
    color: theme.vars.palette.text.secondary,
}));

const Dashboard = () => {

    const [businesses, setBusinesses] = useState<Business[]>(businessData.businesses); // Initialize state with imported data
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                const response = await fetch('src/app/data/business.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }
                const data: BusinessData = await response.json();
                setBusinesses(data.businesses);
                setLoading(false);
                console.log(data)
            } catch (err) {
                const errorMessage = (err as Error).message;
                setError(errorMessage);
                setLoading(false);
            }
        };
        fetchBusinesses();
    }, []);

    return (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid xs={2} position={"sticky"}>
                <SideBar/>
            </Grid>
            <Grid xs={10}>
                <Item>
                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ flexGrow: 1 }}
                    >
                        {businesses.map((business, index) => (
                                <Grid xs={2} sm={4} md={4} key={index}>
                                <Item>
                                    <PostCard business={business}/>
                                </Item>
                            </Grid>
                        ))
                        }
                    </Grid>
                </Item>
            </Grid>
        </Grid>

    );
};
export default Dashboard;

