import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import { Business } from '../types';

export default function PostCard({ business }: { business: Business }) {
    return (
        <Card sx={{ width: 380 }} variant="plain" style={{backgroundColor: "white"}}>
            <CardOverflow style={{backgroundColor: "white"}}>
                <Typography level="title-lg">{business.name}</Typography>
                <Typography level="body-sm">{business.category}</Typography>
                <IconButton
                    aria-label="bookmark Bahamas Islands"
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
                >
                    <BookmarkAdd />
                </IconButton>

            </CardOverflow >
            <CardOverflow style={{backgroundColor: "white"}}>
                <AspectRatio ratio="2">
                <img
                    src={business.src}
                    srcSet={business.srcSet}
                    loading="lazy"
                    alt=""
                />
                </AspectRatio>
            </CardOverflow>
            <CardContent orientation="horizontal" style={{backgroundColor: "white"}}>
                <div>
                    <Typography level="body-xs">Reviews:</Typography>
                    <Typography fontSize="lg" fontWeight="lg">
                        {business.score}
                    </Typography>
                </div>
                <Button
                    variant="solid"
                    size="md"
                    color="primary"
                    aria-label="Explore Bahamas Islands"
                    sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
                >
                    Explore
                </Button>
            </CardContent>
        </Card>
    );
}
