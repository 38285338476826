import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from './app/App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
          <BrowserRouter>
              <React.StrictMode>
                  <App />
              </React.StrictMode>
          </BrowserRouter>
  </React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
