import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "./AuthProvider";
import { PublicAppHeader, PrivateAppHeader } from "../../components/AppHeader";

const Layout = () => {
    const { isLoggedIn } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useContext(AuthContext);

    useEffect(() => {
        if (isLoggedIn && (location.pathname === "/login" || location.pathname === "/")) {
            navigate("/dashboard");
        }
        if (
            isLoggedIn &&
            location.pathname.split("/")[3] === "edit" &&
            id !== location.pathname.split("/")[2]
        ) {
            navigate("/allprojects");
        }
    });

    return (
        <>
            {isLoggedIn ? <PrivateAppHeader /> : <PublicAppHeader />}
            <Outlet />
        </>
    );
};
export default Layout;
