import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { AuthContext } from "../app/auth/AuthProvider";
import AuthService from "../app/auth/AuthService";
import { v4 as uuid } from 'uuid';

interface IFormInput {
    email: string;
    password: string;
    passwordVerify: string;
}

function SignUp() {
    const { setAuthData } = useContext(AuthContext);
    const { control, handleSubmit, getValues } = useForm<IFormInput>();
    const [errorMessage, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
        const password = data.password;
        const email = data.email;
        const username = uuid();
        const name = username
        try {
            /** Add user */
            await AuthService.signUp(username, password, email, username);
            const user = await AuthService.signIn(username, password);
            setAuthData((prevState) => {
                return {
                    ...prevState,
                    isLoggedIn: true,
                    id: user.userId,
                    token: user.jwt,
                };
            });
            navigate("/dashboard");
        } catch (e: unknown) {
            if (e instanceof Error) {
                const errorMessage = e.message;
                setError(errorMessage);
            } else {
                setError("Something went wrong, please try again later!");
            }
        }

    };

    return (
        <form>
            <Box height="100%">
                <Grid container
                    direction={"column"}
                    spacing={2}
                      justifyContent="center">
                    {
                        errorMessage?
                            <Grid item xs={12}>
                                <Alert severity="error" onClose={() => {}} sx={{ width: '100%' }}>
                                    Email or password does not meet the requirements
                                </Alert>
                            </Grid>:
                            <div>

                            </div>
                    }
                    <Grid item>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({
                                         field: { onChange, value = "" },
                                         fieldState: { error },
                                     }) => (
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    type="string"

                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({
                                         field: { onChange, value = "" },
                                         fieldState: { error },
                                     }) => (
                                <TextField
                                    fullWidth
                                    label="Password"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            )}
                            rules={{
                                required: true,
                                minLength: 8,
                                pattern:
                                    /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!?]).*$/,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name="passwordVerify"
                            control={control}
                            defaultValue=""
                            render={({
                                         field: { onChange, value = "" },
                                         fieldState: { error },
                                     }) => (
                                <TextField
                                    fullWidth
                                    label="Verify Password"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    type={showPassword ? "text" : "password"}
                                />
                            )}
                            rules={{
                                required: true,
                                minLength: 8,
                                validate: (value) => {
                                    const { password } = getValues();
                                    return (
                                        password === value ||
                                        "Your passwords do not match"
                                    );
                                },
                            }}
                        />
                    </Grid>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            type="submit"
                            size="large"
                            variant="contained"
                        >
                            Sign Up
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
}

export default SignUp;
