import React, { useState, useContext } from "react";
import { Controller, SubmitHandler, useForm} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, TextField, Alert } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AuthService from "../app/auth/AuthService";
import { AuthContext } from "../app/auth/AuthProvider";

interface IFormInput {
    email: string;
    password: string;
    passwordVerify: string;
}

function SignIn() {
    const { setAuthData } = useContext(AuthContext);
    const { control, handleSubmit } = useForm<IFormInput>();
    const [errorMessage, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
        const password = data.password;
        const email = data.email;
        try {
            /** Add user */
            await AuthService.signIn(email, password);
            const user = await AuthService.signIn(email, password);
            setAuthData((prevState) => {
                return {
                    ...prevState,
                    isLoggedIn: true,
                    id: user.userId,
                    token: user.jwt,
                };
            });
            navigate("/dashboard");
        } catch (e: unknown) {
            if (e instanceof Error) {
                const errorMessage = e.message;
                setError(errorMessage);
            } else {
                setError("Something went wrong, please try again later!");
            }
        }

    };

    return (
        <form>
            <Box height="100%" width={"100%"}
            >
                <Grid container
                      direction={"column"}
                      spacing={2}
                      justifyContent="center">
                    {
                        errorMessage?
                        <Grid item xs={12}>
                            <Alert severity="error" onClose={() => {}} sx={{ width: '100%' }}>
                                Email or password is incorrect
                            </Alert>
                        </Grid>:
                            <div>

                            </div>
                    }
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({
                                         field: { onChange, value = "" },
                                         fieldState: { error },
                                     }) => (
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? "Email is required" : null}
                                    type="string"
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({
                                         field: { onChange, value = "" },
                                         fieldState: { error },
                                     }) => (
                                <TextField
                                    fullWidth
                                    label="Password"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? "Password is required" : null}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                { showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon /> }
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            type="submit"
                            size="large"
                            variant="contained"
                        >
                            Sign In
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
}

export default SignIn;
