import { Outlet } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from './AuthProvider'
import AuthService from "./AuthService";
import Typography from "@mui/material/Typography";

const PersistLogin = () => {

    const [isLoading, setIsLoading] = useState(true)
    const { isLoggedIn, setAuthData } = useContext(AuthContext)

    useEffect(() => {
        const verifyUser = async () => {
            try {
                const verified = await AuthService.isLoggedIn();
                console.log("verify user: " + verified);
                setAuthData(prevState => {
                    return {...prevState, isLoggedIn: verified}
                })
                if(verified){
                    const user = await AuthService.getCurrentUser()
                    setAuthData(prevState => {
                        return {...prevState, id: user.userId, token: user.jwt, isLoggedIn:true }
                    })
                }
            }
            catch (err) {
                setAuthData(prevState => {
                    return {...prevState, 'isLoggedIn': false}
                })
                console.error(err);
            }
            finally {
                setIsLoading(false);
            }
        }

        !isLoggedIn ? verifyUser() : setIsLoading(false)

    }, [])

    return (
        <> {
            isLoading
                ? <Typography>Loading...</Typography>
                : <Outlet/>
        }
        </>
    )
}

export default PersistLogin