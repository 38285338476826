import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import { AuthProvider } from "./auth/AuthProvider";
import Layout from "../app/auth/Layout";
import PersistLogin from "../app/auth/PersistLogin";
import RequireAuth from "../app/auth/RequireAuth";
import Login from "../components/Login";
import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import PageNotFound from "../pages/PageNotFound";
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';

const materialTheme = materialExtendTheme();

const App = () => {
    return (
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
            <JoyCssVarsProvider defaultMode={'dark'} >
                <CssBaseline enableColorScheme />
                    <Box
                        className="App"
                        height={"100vh"}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <AuthProvider>
                            <Routes>
                                <Route element={<PersistLogin />}>
                                    <Route path="/" element={<Layout />}>

                                        {/* public routes */}
                                        <Route path="/" element={<Home />} />
                                        <Route path="/login" element={<Login />} />

                                        {/* protected routes */}
                                        <Route element={<RequireAuth />}>
                                            <Route path="/dashboard" element={<Dashboard />} />
                                        </Route>

                                        <Route path="/*" element={<PageNotFound />} />
                                    </Route>
                                </Route>
                            </Routes>
                        </AuthProvider>
                    </Box>
            </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
    );
};

export default App;