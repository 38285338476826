import React from "react";
import { Box, Typography, Grid, Tab, Tabs } from "@mui/material";
import SignIn from "./SignIn"
import SignUp from "./SignUp"

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const Login = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

        function a11yProps(index: number) {
            return {
                id: `simple-tab-${index}`,
                'aria-controls': `simple-tabpanel-${index}`,
            };
        }

    return (
        <Grid container component="main" sx={{height: '100vh'}}>
           <Grid item xs={5}>
               <Box
                   width="100%"
                   justifyContent="center"
                   alignItems="center"
                   minHeight="100vh"
                   sx={{ flexDirection: 'column', p :"10%", pt:"40%" }}
               >
                   <Typography variant="h6" >Welcome</Typography>
                       <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                           <Tab label="Login" {...a11yProps(0)} />
                           <Tab label="Sign Up" {...a11yProps(1)} />
                       </Tabs>
                       <CustomTabPanel value={value} index={0}>
                       <SignIn />
                   </CustomTabPanel>
                   <CustomTabPanel value={value} index={1}>
                       <SignUp />
                   </CustomTabPanel>
               </Box>
           </Grid>
        </Grid>
    );
}

export default Login;
