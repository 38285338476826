import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';

export default function PersistentDrawerLeft() {

    const getIcon = (iconName: string) => {
        if(iconName === "Home")
            return (
                <HomeIcon />
            )
        else if(iconName == "Liked reviews")
            return (
                <ThumbUpOutlinedIcon />
            )
        else if(iconName == "Liked pages")
            return (
                <FavoriteBorderOutlinedIcon />
            )
        else if(iconName == "Near me")
            return (
                <PushPinOutlinedIcon />
            )
        else if(iconName == "Trending")
            return (
                <WhatshotOutlinedIcon />
            )
        else if(iconName == "Appointments")
            return (
                <CalendarMonthOutlinedIcon />
            )
        else if(iconName == "Subscriptions")
            return (
                <SubscriptionsOutlinedIcon />
            )
        else if(iconName == "Your reviews")
            return (
                <RateReviewOutlinedIcon />
            )
        else
            return (
                <NotInterestedIcon/>
            )
    }

    return (
        <Box
            width="100%"
            height="100%"
            id="drawer-container"
            position="relative"
            bgcolor="white"
            component="div"
            style={{ overflowY: "scroll", overflowX: "unset", position: "fixed"}}
            pl={5}
        >
            <Drawer
                open={true}
                PaperProps={{ style: { position: "absolute", width: "486px" } }}
                BackdropProps={{ style: { position: "absolute" } }}
                ModalProps={{
                    container: document.getElementById("drawer-container"),
                    style: { position: "absolute" },
                }}
                SlideProps={{
                    appear: false
                }}
                variant={"permanent"}
            >
                <List>
                    {['Home', 'Subscriptions'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {getIcon(text)}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider sx={{width:"210px", paddingLeft:"15px"}}/>
                <Typography align={"left"} pl={2}>
                    You
                </Typography>
                <List>
                    {['Your reviews', 'Liked Reviews', 'Liked pages', 'Appointments'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {getIcon(text)}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider sx={{width:"210px", pl:"5"}} />
                <Typography pl={2}>
                    Explore
                </Typography>
                <List>
                    {['Trending', 'Near me'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {getIcon(text)}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider sx={{width:"210px", pl:"5"}} />
                <Typography pl={2}>
                    Businesses
                </Typography>
                <List>
                    {['Payment'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {getIcon(text)}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </Box>
    )
}
