import { Auth } from 'aws-amplify';

/**
 * Cognito's authentication services
 */
class AuthService{

    /**
     * Allow user to sign in
     * @params username The username of the user
     * @params password The user's password
     */
    signIn = async (email:string, password:string) => {
        await Auth.signIn(email, password)
        return await this.getCurrentUser()
    }

    /**
     * Allow user to sign up
     * @params username The username of the user
     * @params password The user's password
     * @params email    The user's email
     */
    signUp = async (username:string, password: string, email:string, name:string) => {
        await Auth.signUp({username, password, attributes: {
                email,
                name
            }
        });
    }

    /**
     * Get the current signed in user
     */
    getCurrentUser = async () => {
        return await Auth.currentSession().then(res=>{
            let accessToken = res.getAccessToken();
            let userId = accessToken.payload.sub;
            let jwt = accessToken.getJwtToken();
            return { userId, jwt }
        })
    };

    /**
     * Check if the current user is logged in
     */
    isLoggedIn = async () => {
        return await Auth.currentAuthenticatedUser().then(() => {
            return true
        }).catch(() => {
            return false
        })
    };

    /**
     * Allow the current user to sign out
     */
    signOut = async () => {
        return await Auth.signOut()
    }
}
export default new AuthService()