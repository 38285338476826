import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useContext } from "react"
import { AuthContext } from './AuthProvider'

const RequireAuth = () => {

    const { isLoggedIn } = useContext(AuthContext)
    const location = useLocation();

    return (
        isLoggedIn
            ? (<Outlet />)
            : (< Navigate to="/login" state={{ from: location }} replace />)
    )
}
export default RequireAuth