import React from 'react';
import { Alert, Box } from "@mui/material";

const PageNotFound = () => {
    return (
        <Box>
            <Alert severity="error" sx={{ width: '100%' }}>
                The Page you are looking for does not exist.
            </Alert>
        </Box>
    );
};
export default PageNotFound;